import PartySocket from "partysocket";


import { useEffect, useState } from "react";

const StreamComponent = () => {
  const [streamedContent, setStreamedContent] = useState("");

  useEffect(() => {
    const fetchStream = async () => {

      const response = await fetch(`https://${PARTYKIT_HOST}/parties/main/index`, {
        method: "POST",
        body: JSON.stringify({ message: "welcome me" })
      })

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let done = false;

      while (!done) {
        const { value, done: readerDone } = await reader.read();
        done = readerDone;

        if (value) {
          const decodedChunk = decoder.decode(value);

          // Split the streamed content into individual "data" lines
          const lines = decodedChunk.split("\n").filter((line) => line.startsWith("data:"));

          // Extract and concatenate the "response" fields
          lines.forEach((line) => {
            const jsonData = line.slice(5).trim(); // Remove "data:" prefix and trim whitespace

            if (jsonData === "[DONE]") {
              // Handle the special "[DONE]" message (if needed, e.g., stop processing)
              console.log("Stream complete.");
              return;
            }

            try {
              const json = JSON.parse(jsonData); // Remove "data:" prefix and parse
              if (json.response) {
                setStreamedContent((prev) => prev + json.response);
              }
            } catch (error) {
              console.error("Error parsing JSON:", error);
            }
          });
        }
      }
    };

    fetchStream().catch((error) =>
{
}    );
  }, []);

  return (
    <div style={{	maxWidth: "65ch", margin: "auto"}}>
      <p>{streamedContent}</p>
    </div>
  );
};

export default StreamComponent;
